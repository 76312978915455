<template>
  <el-select
    v-model="value"
    multiple
    filterable
    allow-create
    default-first-option
    remote
    reserve-keyword
    placeholder="请输入标签"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="onChange"
    style="width: 100%;"
  >
    <el-option
      v-for="(item, index) in options"
      :key="index"
      :label="item.label"
      :value="item.value"
    >
      <!-- <span style="float: left">{{ item.value }}</span>
      <span style="float: right; color: #8492a6; font-size: 1rem;">
        <i class="el-icon-circle-close"></i>
      </span> -->
    </el-option>
  </el-select>
</template>

<script>
const host = process.env.VUE_APP_HOST_URL;
export default {
  data() {
    return {
      url: `${host}/note/tag/search`,
      options: [], // 要添加的选项
      value: [], // 已经添加的列表
      loading: false,
    };
  },
  props: {
    // url: String,
    // search: Function,
    change: Function,
    tags: Array,
  },
  mounted() {
    this.value = this.tags;
  },
  watch: {
    tags(newData, oldData) {
      console.log("watch::", newData, oldData);
      this.value = newData;
    },
  },
  methods: {
    async remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        let resp = await this.axios.get(this.url, {
          params: { s: query },
        });
        this.options = resp.data.data.map((s) => {
          return { value: s.value, label: s.value };
        });
        this.loading = false;
      } else {
        this.options = [];
      }
    },
    // 值改变时，触发回调
    onChange(e) {
      // this.tags = this.value;
      console.log("change::", e);
      let needAdd = "";
      let newOne = e[e.length - 1];
      console.log("new one:", newOne);
      let filter = this.options.filter((s) => s.value == newOne);
      console.log("filter:", filter, filter.length);
      if (filter.length == 0) {
        needAdd = newOne;
      }
      this.change && this.change(e, needAdd);
    },
  },
};
</script>
