<template>
  <div class="layout-content">
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
        <el-breadcrumb-item :to="{ path: '/note' }">博客列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/note/detail/${note.id}` }">
          <ScrollText :title="note.title || '未命名'" width="300px" />
        </el-breadcrumb-item>
        <el-breadcrumb-item>编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row type="flex" style="height: 100%;">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="text">
        <el-row type="flex" justify="space-between" align="middle">
          <el-col :span="20"
            ><input class="blog-title-input" type="text" v-model="note.title" placeholder="未命名"
          /></el-col>
          <el-col :span="4" style="text-align: right;">
            <el-button size="small" type="danger" plain @click="onRemove"
              ><i class="el-icon-delete"></i> 删除文章</el-button
            >
          </el-col>
          <!-- <h1 class="blog-title">{{title || "未命名"}}</h1> -->
        </el-row>
        <!-- <el-row class="blog-create-date">创建日期：{{formatDate(createAt)}}</el-row> -->
        <el-row>
          <SearchSelect v-bind:tags="note.tags" :change="tagChange" style="padding: 10px 0px" />
        </el-row>
        <el-row class="blog-content">
          <mavon-editor
            id="editor"
            defaultOpen="edit"
            codeStyle="atom-one-light"
            previewBackground="#fff"
            ref="md"
            :boxShadow="true"
            :autofocus="true"
            :toolbarsFlag="true"
            :editable="true"
            :subfield="false"
            v-model="note.content"
            @save="onSave"
            @change="onChange"
            @imgAdd="onAddImg"
            @imgDel="onDelImg"
          />
        </el-row>
        <el-row>
          <el-button class="zan bc bc-like">1</el-button>
        </el-row>
      </el-col>
      <!-- <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="nav">
        <router-link :to="`/note/detail/${$route.params.id}/edit`">
          <el-button>编辑</el-button>
        </router-link>
        <el-input placeholder="搜索" v-model="search">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <p>目录</p>
      </el-col>-->
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
import { store } from "../store";
import ScrollText from "../components/ScrollText";
import SearchSelect from "../components/SearchSelect";

const host = process.env.VUE_APP_HOST_URL;

export default {
  data() {
    return {
      defaultContent: "", // 默认的内容，保存后刷新
      search: "",
      noteInfo: {},
      note: {
        id: "",
        createAt: null,
        updateAt: null,
        title: "",
        tags: [],
        author: "",
        content: "",
      },
    };
  },
  components: {
    ScrollText,
    SearchSelect,
  },
  computed: {},
  mounted() {
    // 切换主题
    store.setTheme("light");
    console.log("id:", this.$route.params.id);
    this.fetchInfo(this.$route.params.id);
    // hljs.initHighlightingOnLoad();
  },
  // watch: { // 监听到数据然后赋值
  //     noteInfo (newData) {
  //       this.note = newData
  //     }
  // },
  methods: {
    formatDate(date) {
      return moment(date).format("L");
    },
    async fetchInfo(blogId) {
      const host = process.env.VUE_APP_HOST_URL;
      try {
        let resp = await this.axios.get(host + "/note/get", {
          params: { id: blogId },
        });
        let result = resp.data.data;
        if (result == null) {
          this.$notify({
            title: "加载文章失败",
            message: resp.data,
          });
          return;
        }
        console.log(resp);
        this.note = { ...this.note, ...result, id: blogId };
        console.log("fetch note:", this.note);
        this.defaultContent = result.content; // 默认内容
        this.$notify({
          title: "加载文章成功",
          duration: 600,
        });
      } catch (e) {
        this.$notify({
          title: "加载文章失败",
          message: e,
        });
        console.log(e);
      }
    },
    async onSave() {
      console.log("on save");

      try {
        let updateData = {
          id: this.note.id,
          title: this.note.title,
          content: this.note.content,
          author: this.note.author,
          tags: this.note.tags,
        };
        let resp = await this.axios.post(host + "/note/update", updateData);
        // console.log("updateData::", updateData);
        let result = resp.data;
        if (result.err) {
          this.$notify({
            title: "更新失败",
            message: result.errMsg,
          });
          return;
        }

        let data = result.data;
        console.log(resp.data);
        this.note = { ...this.note, ...data };

        let urls = this.getRemovedUrls(this.note.content, this.defaultContent);
        this.removeImgs(urls); // 删除不存在的图片

        this.defaultContent = data.content; // 更新默认内容

        this.$notify({
          title: "保存成功",
          duration: 600,
        });
      } catch (e) {
        this.$notify({
          title: "保存失败",
          message: e,
        });
      }
    },
    // 删除文章
    async onRemove() {
      try {
        let resp = await this.axios.post(host + "/note/remove", {
          id: this.id,
        });
        console.log(resp);
        this.$router.go(-1);
      } catch (e) {
        console.log("err", e);
      }
    },
    // 添加图片
    async onAddImg(pos, file) {
      console.log(pos, file);
      let formdata = new FormData();
      formdata.append("category", "blog");
      formdata.append("file", file);
      console.log("formdata", formdata);
      try {
        let resp = await this.axios({
          url: host + "/oss/add",
          method: "post",
          data: formdata,
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(resp);
        this.$refs.md.$img2Url(pos, resp.data.data.fullUrl);
      } catch (e) {
        this.$notify({
          title: "保存图片失败",
          message: e,
        });
      }
    },
    // 删除图片
    async onDelImg(name) {
      console.log(name);
    },
    // 删除图片
    async removeImgs(urls = []) {
      if (urls.length == 0) {
        return;
      }
      try {
        let resp = await this.axios({
          url: host + "/oss/remove/urls",
          method: "post",
          data: { urls },
          // headers: { "Content-Type": "multipart/form-data" }
        });
        this.$notify({
          title: "图片已删除",
          message: resp.data,
        });
      } catch (e) {
        this.$notify({
          title: "删除图片失败",
          message: e,
        });
      }
    },
    onChange() {
      // console.log("on change", e);
    },
    // 获取消失的 urls
    getRemovedUrls(nowText, initText) {
      let nowUrls = this.getUrls(nowText);
      let initUrls = this.getUrls(initText);
      let removedUrls = [];
      // 判断老的 url 在新的记录里还有没有了
      for (let i in initUrls) {
        let notExist = true;
        for (let j in nowUrls) {
          // 还在
          if (initUrls[i] == nowUrls[j]) {
            notExist = false;
            break;
          }
        }
        // 不在了
        if (notExist) {
          removedUrls.push(initUrls[i]); // 把不在的这条放进欲删数组
        }
      }
      return removedUrls;
    },
    // 获取 markdown 所有Urls
    getUrls(text = "") {
      let list = text.match(/!\[.*\]\(.*\)/g);
      if (!list) {
        return [];
      }
      return list.map((s) => s.replace(/!\[.*\]\((.*)\)/, "$1"));
    },
    // 添加tag
    async addTag(tag) {
      try {
        let resp = await this.axios({
          url: `${host}/note/tag/add`,
          method: "post",
          data: { value: tag },
        });
        this.$notify({
          title: "标签已添加",
          message: resp.data,
        });
      } catch (e) {
        this.$notify({
          title: "标签添加失败",
          message: e,
        });
      }
    },
    // 标签变更
    tagChange(tags, needAddTag) {
      this.note.tags = tags;
      if (needAddTag) {
        // 异步添加Tag
        // this.addTag(needAddTag);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  background-color: #fff;
}
.text {
  padding: 0px 10px;
  background-color: #fff;
}
.nav {
  padding: 0px 10px;
}
.blog-title {
  font-size: 2rem;
  &:hover {
    &::before {
      float: left;
      position: absolute;
      left: -1.3rem;
      line-height: 1.4;
      content: "#";
    }
  }
}
.blog-title-input {
  margin: 1rem 0px;
  font-size: 2rem;
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
}
.blog-create-date {
  font-size: 0.8rem;
  color: #aaa;
}
.blog-content {
  min-height: 80vh;
}
// .blog-content {
// background-color: #fafafa;
// border-radius: 5px;
// padding: 10px 10px;
// min-height: 60vh;
// line-height: 1.8;
// font-size: 15px;
// }
.blog-tag {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #007942;
  border: none;
  background-color: #e9f3ef;
}
.zan {
  display: table;
  // margin: auto;
  height: 40px;
  width: 60px;
  border-radius: 20px;
  font-size: 1.2rem;
  padding: 0px 12px;
  margin: 10px auto;
}

/* 覆盖默认样式 */
#editor {
  border: none;
  font-size: 15px;
  line-height: 1.8;
  min-height: 75vh;
  /deep/ .v-show-content {
    padding: 0px;
  }
  /deep/ .v-note-wrapper {
    border: none;
  }
  /deep/ pre {
    padding: 6px;
    background-color: #fafafa;
    border-radius: 6px;
  }
}

@keyframes breadcrumbTitle {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(-100% + 300px));
  }
  100% {
    transform: translateX(0);
  }
}

.breadcrumb-title {
  vertical-align: top;
  display: inline-block;
  max-width: 300px;
  overflow: hidden;

  .breadcrumb-title-text {
    margin-left: 0px;
    white-space: nowrap;
    width: fit-content;
    vertical-align: top;
    display: inline-block;
    &:hover {
      animation: breadcrumbTitle 10s infinite;
    }
  }
}
</style>
